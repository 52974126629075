<template>
  <div
    v-video-player:myVideoPlayer="specs"
    class="vjs-big-play-centered"
  />
</template>

<script>
export default {
  data: () => ({
    specs: {
      language: 'en',
      fluid: true,
      sources: [{
        type: 'video/mp4',
        src: '/vids/AAWebsite_lq.mp4'
      },
      {
        type: 'video/webm',
        src: '/vids/AAWebsite_lq.webm'
      }]
    }
  })
}
</script>