<template>
  <v-row>
    <v-col
      class="text-justify text-center mb-4"
      cols="12"
      xl="8"
      offset-xl="2"
    >
      <p class="headline font-weight-thin">
        {{ $t('homepage.services.title') }}
      </p>
      <p
        v-if="$t('homepage.services.description')"
        class="subheading"
      >
        {{ $t('homepage.services.description') }}
      </p>
    </v-col>
    <v-col
      cols="12"
      xl="10"
      offset-xl="1"
    >
      <v-row
        justify="center"
      >
        <v-col
          v-for="(service, i) in servicesToDisplay"
          :key="i"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card
            nuxt
            hover
            class="rounded-card fill-height"
          >
            <!-- :to="service.to" -->
            <v-img
              :src="require(`@/static/img/services/${service.image}.jpg?vuetify-preload`)"
              :alt="service.title"
              aspect-ratio="2.25"
            />
            <v-card-title class="title font-weight-light justify-center">
              {{ service.title }}
            </v-card-title>
            <v-card-text class="text-center body-2">
              {{ service.description }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="maxItems"
      class="text-center pt-5"
      cols="12"
    >
      <v-btn
        color="success"
        large
      >
        {{ $t('buttons.viewAll') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    language: {
      type: String,
      default: 'nl'
    },
    maxItems: {
      type: [Number, String],
      default: 0
    }
  },
  data(){
    return {
      services: [
        {
          image: 'instore',
          title: this.$t('homepage.services.shopAlong.title'),
          description: this.$t('homepage.services.shopAlong.description'),
          to: this.localePath('services-neuromarketing-shopper')
        },
        {
          image: 'packaging',
          title: this.$t('homepage.services.packaging.title'),
          description: this.$t('homepage.services.packaging.description'),
          to: this.localePath('services-neuromarketing-packaging')
        },
        {
          image: 'ads',
          title: this.$t('homepage.services.ads.title'),
          description: this.$t('homepage.services.ads.description'),
          to: this.localePath('services-neuromarketing-ads')
        },
        {
          image: 'literature',
          title: this.$t('homepage.services.science.title'),
          description: this.$t('homepage.services.science.description'),
          to: this.localePath('services-general-literature')
        },
        {
          image: 'wayfinding',
          title: this.$t('homepage.services.wayfinding.title'),
          description: this.$t('homepage.services.wayfinding.description'),
          to: this.localePath('services-humanfactors-wayfinding')
        },
        {
          image: 'consulting-small',
          title: this.$t('homepage.services.consultancy.title'),
          description: this.$t('homepage.services.consultancy.description'),
          to: this.localePath('services-general-consultancy')
        }
      ]
    }
  },
  computed: {
    servicesToDisplay() {
      if (this.maxItems && !isNaN(this.maxItems)) {
        return this.services.slice(0, this.maxItems)
      } else {
        return this.services
      }
    }
  }
}
</script>
