<template>
  <v-container
    fluid
    style="margin-top: -76px"
  >
    <v-row
      class="mx-n3"
    >
      <v-img
        dark
        src="@/static/img/banners/aa-banner.jpg"
        :height="$vuetify.breakpoint.smAndDown?'250px':'500px'"
      >
        <v-row
          class="fill-height px-3"
        >
          <v-col
            cols="12"
            lg="10"
            offset-lg="1"
          >
            <v-row class="fill-height">
              <v-col
                cols="12"
                md="6"
                class="white--text"
                align-self="center"
              >
                <h1 class="hidden-sm-and-down display-4">
                  Attention<br>Architects
                </h1>
                <h1 class="hidden-md-and-up display-3">
                  Attention<br>Architects
                </h1>
              </v-col>
              <v-col
                class="hidden-sm-and-down"
                md="6"
              >
                <v-row
                  class="fill-height"
                  align="end"
                  justify="end"
                >
                  <v-col class="headline white--text text--shadow text-right">
                    <i v-html="$t('homepage.headline')" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-img>
    </v-row>

    <v-row
      :class="containerStyles"
    >
      <v-col
        cols="12"
        lg="10"
        offset-lg="1"
      >
        <v-row align="center">
          <v-col
            cols="12"
            lg="5"
          >
            <div
              class="text-justify subheading"
              v-html="intro.html"
            />
          </v-col>

          <v-col
            class="text-center"
            cols="12"
            lg="6"
            sm="8"
            offset-sm="2"
            offset-lg="1"
          >
            <aa-intro-video />
            <span v-if="language==='en'">
              This video is currently only available in Dutch. We are working on an English version.
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      class="shaded"
    >
      <v-col
        cols="12"
        md="6"
        lg="6"
        class="pa-0"
      >
        <v-img
          src="@/static/img/techniques/surveys.jpg"
          max-height="400px"
          min-height="325px"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="6"
      >
        <v-row
          class="fill-height"
          align="center"
        >
          <v-col
            class="d-flex"
            cols="12"
            lg="10"
            xl="9"
            :style="inset"
          >
            <v-card
              v-bind="surveyCardParams"
              :class="{'mb-4': $vuetify.breakpoint.smAndDown, 'pa-4': $vuetify.breakpoint.mdAndUp}"
            >
              <v-card-text
                class="text-justify body-1"
                v-html="consciousness.html"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row
      class="py-3"
      align="center"
    >
      <v-col
        cols="12"
        lg="10"
        offset-lg="1"
      >
        <aa-techniques
          :biometrics="biometrics.html"
          :big-n="bigN.html"
        />
      </v-col>
    </v-row>

    <v-row
      class="shaded py-3"
      align="center"
    >
      <v-col
        cols="12"
        lg="10"
        offset-lg="1"
      >
        <aa-services :language="language" />
      </v-col>
    </v-row>

    <v-row class="py-3">
      <v-col
        xl="10"
        offset-xl="1"
      >
        <aa-clients />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import aaServices from '@/components/home/AaServices'
import aaTechniques from '@/components/home/AaTechniques'
import aaIntroVideo from '@/components/home/AaIntroVideo'
import aaClients from '@/components/home/AaClients'

export default {
  components: {
    aaServices,
    aaTechniques,
    aaIntroVideo,
    aaClients
  },
  async asyncData({ app }) {
    const lang = app.i18n.locale
    return {
      intro: await import(`@/content/index/${lang}/intro.md`),
      consciousness: await import(`@/content/index/${lang}/consciousness.md`),
      biometrics: await import(`@/content/index/${lang}/biometrics.md`),
      bigN: await import(`@/content/index/${lang}/bigN.md`)
    }
  },
  computed: {
    containerStyles: () => 'py-4 my-3',
    language () {
      return this.$i18n.locale
    },
    inset () {
      return this.$vuetify.breakpoint.mdAndUp
      ? {'margin-left' : '-30px'}
      : {'margin-top' : '-30px'}
    },
    surveyCardParams () {
      return {
        // flat: !!this.$vuetify.breakpoint.smAndDown,
        elevation: this.$vuetify.breakpoint.smAndDown ? null : 5
      }
    }
  },
  head () {
    return {
      title: this.$t('nav.home')
    }
  }
}
</script>
