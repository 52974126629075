<template>
  <v-row>
    <v-col
      cols="12"
    >
      <p class="headline font-weight-thin text-center">
        {{ $t('homepage.clients.title') }}
      </p>
    </v-col>
    <v-col
      v-for="client in clients"
      :key="client.name"
      class="px-4"
      cols="12"
      sm="6"
      md="4"
      lg="2"
    >
      <a
        :href="client.site"
        target="_BLANK"
      >
        <v-img
          :src="client.logo"
          contain
          max-height="100px"
        />
      </a>
    </v-col>
  </v-row>
</template>

<script>
const logoPath = '/img/logos'
export default {
  data() {
    return {
      clients: [
        {
          name: 'Vueling',
          logo: `${logoPath}/vueling.svg`,
          site: 'https://www.vueling.com/en'
        },
        {
          name: 'SecuCare',
          logo: `${logoPath}/secucare.png`,
          site: 'https://secucare.nl/'
        },
        {
          name: 'Vrumona',
          logo: `${logoPath}/vrumona.svg`,
          site: 'https://www.vrumona.nl/'
        },
        {
          name: 'RWS',
          logo: `${logoPath}/rws.png`,
          site: 'https://www.rijkswaterstaat.nl/'
        },
        {
          name: 'H&M',
          logo: `${logoPath}/h&m.png`,
          site: 'https://www.hm.com/'
        },
        {
          name: 'DNB',
          logo: `${logoPath}/dnb.png`,
          site: 'https://www.dnb.nl/'
        }
      ]
    };
  }
};
</script>
