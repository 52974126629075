<template>
  <v-row
    class="fill-height"
  >
    <v-col
      cols="12"
    >
      <v-row justify="center">
        <v-col
          class="text-center"
          cols="12"
          xl="8"
        >
          <p class="headline font-weight-thin">
            {{ $t('homepage.techniques.title') }}
          </p>
          <p
            v-if="$t('homepage.techniques.intro')"
            class="subheading"
          >
            {{ $t('homepage.techniques.intro') }}
          </p>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-row
        class="fill-height"
        justify="space-around"
      >
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          offset-lg="0"
          lg="6"
          xl="5"
        >
          <v-card
            elevation="8"
            min-height="100%"
          >
            <v-card-text>
              <v-row
                :class="responsivePadding"
              >
                <v-col cols="12">
                  <div
                    class="text-justify body-1"
                    v-html="biometrics"
                  />
                </v-col>
                <v-col>
                  <div
                    ref="lnEyeHead"
                    class="primary"
                    :style="lineStyle"
                  />
                  <div
                    ref="lnFaceHead"
                    class="primary"
                    :style="lineStyle"
                  />
                  <div
                    ref="lnHeartTorso"
                    class="primary"
                    :style="lineStyle"
                  />
                  <div
                    ref="lnDropHand"
                    class="primary"
                    :style="lineStyle"
                  />
                  <v-row
                    v-resize="onResize"
                  >
                    <v-col
                      cols="8"
                      sm="6"
                      lg="5"
                    >
                      <v-row
                        justify="end"
                      >
                        <v-img
                          ref="humanBody"
                          contain
                          src="/img/humanbody.png"
                          style="min-height: 100px; max-width: 100%; max-height: 400px"
                        />
                      </v-row>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="6"
                      lg="7"
                    >
                      <v-row
                        class="pl-3"
                        align="start"
                      >
                        <v-col
                          v-for="channel in channels"
                          :key="channel.icon"
                          class="py-1"
                          cols="12"
                        >
                          <v-menu
                            left
                            offset-x
                            :open-on-hover="$vuetify.breakpoint.mdAndUp"
                            transition="slide-x-reverse-transition"
                            :nudge-width="300"
                            :max-width="375"
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :ref="channel.ref"
                                dark
                                large
                                fab
                                :color="channel.color"
                                v-on="on"
                              >
                                <v-icon size="45">
                                  {{ channel.icon }}
                                </v-icon>
                              </v-btn>
                              <div style="clear:both" />
                              <span class="hidden-xs-only pl-2">{{ channel.label }}</span>
                            </template>
                            <v-card>
                              <v-img
                                :src="channel.image"
                                aspect-ratio="2.2"
                                :position="channel.position || 'top'"
                                class="primary"
                                gradient="to bottom, rgba(63,81,181, .1), rgba(25,32,72, .7)"
                                @load="recalculateLines"
                              >
                                <v-container
                                  fluid
                                  class="bottom-gradient fill-height pa-3"
                                >
                                  <v-row
                                    class="fill-height"
                                    align="end"
                                  >
                                    <v-col
                                      class="align-end flexbox"
                                      cols="12"
                                    >
                                      <div
                                        class="display-1 font-weight-thin white--text"
                                        v-text="channel.label"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-img>
                              <v-card-text class="text-justify px-4">
                                {{ channel.description }}
                              </v-card-text>
                              <!-- <v-card-actions>
                            <v-spacer />
                            <v-btn nuxt color="primary" :href="channel.href">
                              {{ $t('buttons.readMore') }}
                            </v-btn>
                          </v-card-actions> -->
                            </v-card>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="10"
          offset-md="1"
          lg="6"
          offset-lg="0"
          xl="5"
        >
          <v-card
            elevation="8"
            min-height="100%"
          >
            <v-card-text>
              <v-row
                class="fill-height"
                :class="responsivePadding"
              >
                <v-col cols="12">
                  <div
                    class="text-justify body-1"
                    v-html="bigN"
                  />
                </v-col>
                <v-col>
                  <v-row>
                    <v-col
                      class="d-flex align-center text-center"
                      cols="9"
                    >
                      <v-icon
                        ref="globe"
                        color="indigo"
                        :size="$vuetify.breakpoint.smAndUp?250:200"
                      >
                        mdi-web
                      </v-icon>
                    </v-col>
                    <v-col cols="3">
                      <v-row>
                        <v-col
                          v-for="n in users"
                          :key="n"
                        >
                          <div
                            :ref="`lnUser${n}`"
                            class="primary"
                            :style="lineStyle"
                          />
                          <v-img
                            :ref="`user${n}`"
                            contain
                            src="/img/techniques/user.svg"
                            style="max-width: 80px"
                            @load="recalculateLines"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      class="text-center"
                      cols="12"
                      lg="6"
                    >
                      <div
                        ref="lnChart"
                        class="green"
                        :style="lineStyle"
                      />
                      <div ref="chart">
                        <v-avatar
                          color="secondary"
                          size="70"
                        >
                          <v-icon
                            color="white"
                            size="40"
                          >
                            mdi-chart-bell-curve
                          </v-icon>
                        </v-avatar>
                        <v-avatar
                          color="secondary"
                          size="70"
                        >
                          <v-icon
                            color="white"
                            size="40"
                          >
                            mdi-chart-bar
                          </v-icon>
                        </v-avatar>
                        <v-avatar
                          color="secondary"
                          size="70"
                        >
                          <v-icon
                            color="white"
                            size="40"
                          >
                            mdi-chart-line
                          </v-icon>
                        </v-avatar>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import isArray from 'lodash/isArray'

export default {
  props: {
    biometrics: {
      type: String,
      required: true
    },
    bigN: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      channels: [
        {
          ref: 'eyeButton',
          icon: 'mdi-eye',
          color: 'indigo',
          image: '/img/techniques/eyetracking.jpg',
          label: this.$t('homepage.techniques.eyetracking.label'),
          description: this.$t('homepage.techniques.eyetracking.description'),
          href: '/showcase'
        },
        {
          ref: 'emoButton',
          icon: 'mdi-emoticon-excited-outline',
          color: 'indigo',
          image: '/img/techniques/facialexpression.jpg',
          label: this.$t('homepage.techniques.facial.label'),
          description: this.$t('homepage.techniques.facial.description'),
          position: 'center'
        },
        {
          ref: 'heartButton',
          icon: 'mdi-heart-pulse',
          color: 'indigo',
          image: '/img/techniques/ecg.jpg',
          label: this.$t('homepage.techniques.ecg.label'),
          description: this.$t('homepage.techniques.ecg.description')
        },
        {
          ref: 'sweatButton',
          icon: 'mdi-water',
          color: 'indigo',
          image: '/img/techniques/gsr.jpg',
          label: this.$t('homepage.techniques.gsr.label'),
          description: this.$t('homepage.techniques.gsr.description')
        }
      ],
      users: 3,
      lineStyle: 'transform-origin: top left; height: 2px; position: absolute; box-shadow: 2px 2px 4px rgba(0,0,0,.5);'
    }
  },
  computed: {
    responsivePadding(){
      return {'pa-4': true}
    }
  },
  mounted() {
    this.onResize()
  },
  methods: {
    onResize() {
      setTimeout(this.recalculateLines, 500)
    },
    getRect (ref) {
      let el = this.$refs[ref]
      if (!el) throw new ReferenceError(`Reference not found: ${ref}`)
      if (isArray(el)) el = el[0]

      const item = el.$el ? el.$el : el

      return {
        left: item.offsetLeft,
        top: item.offsetTop,
        right: item.offsetLeft + item.offsetWidth,
        bottom: item.offsetTop + item.offsetHeight,
        width: item.offsetWidth,
        height: item.offsetHeight
      }
    },
    recalculateLines() {
      const bodyRect = this.getRect('humanBody')
      const eyeButton = this.getRect('eyeButton')
      const emoButton = this.getRect('emoButton')
      const heartButton = this.getRect('heartButton')
      const sweatButton = this.getRect('sweatButton')
      const globeRect = this.getRect('globe')
      const chartRect = this.getRect('chart')

      this.line('lnEyeHead',
        bodyRect.left+bodyRect.width/1.8, bodyRect.top+bodyRect.height/18,
        eyeButton.left, eyeButton.top+eyeButton.height/2)
      this.line('lnFaceHead',
        bodyRect.left+bodyRect.width/1.9, bodyRect.top+bodyRect.height/12,
        emoButton.left, emoButton.top+emoButton.height/2)
      this.line('lnHeartTorso',
        bodyRect.left+bodyRect.width/1.9, bodyRect.top+bodyRect.height/3.5,
        heartButton.left, heartButton.top+heartButton.height/2)
      this.line('lnDropHand',
        bodyRect.right-bodyRect.width/7, bodyRect.top+bodyRect.height/1.8,
        sweatButton.left, sweatButton.top+sweatButton.height/2)
      this.line('lnChart',
        globeRect.left + globeRect.width/2, globeRect.bottom-10,
        chartRect.left + chartRect.width/2, chartRect.top-5)

      for (let i = 1; i <= this.users; i++) {
        let userRect = this.getRect(`user${i}`)
        this.line(`lnUser${i}`,
          globeRect.right-globeRect.width/2+105, globeRect.top+globeRect.height/2,
          userRect.left, userRect.top+userRect.height/2)
      }
    },
    line(ref, x1, y1, x2, y2) {
      let tmp
      if (x2 < x1) {
        tmp = x2 ; x2 = x1 ; x1 = tmp
        tmp = y2 ; y2 = y1 ; y1 = tmp
      }
      // subtract padding-top correction
      const lineLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
      const m = (y2 - y1) / (x2 - x1)
      const degree = Math.atan(m) * 180 / Math.PI
      let lineEl = this.$refs[ref]
      if (!lineEl) throw new ReferenceError(`Line not found: ${ref}`)
      if (isArray(lineEl)) lineEl = lineEl[0]
      lineEl.style.transform = `rotate(${degree}deg)`
      lineEl.style.width = `${lineLength}px`
      lineEl.style.top = `${y1}px`
      lineEl.style.left = `${x1}px`
    }
  }
};
</script>